<script>
import Base from '@backend/Base.vue';
import Gen from '@helper/Gen';

export default {
	name: "BoProfile",
	extends: Base,
  data(){
    return {
			Name:"BoProfile",
			mrLevel: {},
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
		this.refreshData(()=>{
      setTimeout(()=>{this.$set(this.row, 'type', 'update')},500)
    })
  },
  methods:{
    successFormCallback(resp){
			Gen.info(resp.message, "success", 3000)
			this.refreshUser()
    }
  },
  watch:{
    '$route.query'(v){
			this.refreshData()
		}
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			FORM CRUD
		============================================================================= -->
		<div class="card">
			<VForm @resp="submitForm" method="post">
				<!-- <div class="panel-heading">
					<h2 class="panel-title">{{PageName}}</h2>
				</div> -->
				<div class="card-body">
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-9">
							<BoField name="bu_full_name" v-model="row.bu_full_name"></BoField>
							<BoField name="bu_username" v-model="row.bu_username" keyFilter="usernameKey">
								<span slot="help" class="help-block">
									<small>{{ fields.bu_username }} for login to Backoffice page</small>
								</span>
							</BoField>
							<BoField name="bu_password" v-model="row.bu_password" :attr="{type:'password'}"></BoField>							
							<BoField name="bu_email_address" v-model="row.bu_email_address" :attr="{type:'email'}"></BoField>
							<BoField name="bu_phone" v-model="row.bu_phone"></BoField>
						</div>

						<div class="col-sm-3">
							<BoField name="bu_pic">
								<Uploader name="bu_pic" type="users" uploadType="cropping" :param="{thumbnail:true}" v-model="row.bu_pic"></Uploader>
							</BoField>
						</div>

						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
